import styled from "@emotion/styled";
import { NoImage } from "styles/App.styled";
import { colors } from "styles/colors";
import { fonts } from "styles/fonts";
import {
  Card,
  CardInfo,
  CardTime,
  CardImage,
  CardBody,
  CardIconWrap,
  PaymentBackground,
} from "styles/MainCards.styled";

export const Arrow = styled.div`
  width: 100%;
  padding: 8px 0;
`;
export const SlideArrowWrap = styled.div`
  bottom: 0;
  width: 100%;
  position: fixed;
  padding: 0 8px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 5;
  button {
    width: 100%;
  }
  div {
    display: flex;
    ${Arrow} + ${Arrow} {
      margin-left: 10px;
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
export const ArrowPrev = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 240px;
  cursor: pointer;
  z-index: 2;
  &:hover {
    .icon {
      stroke: ${colors.RED};
    }
  }
  .icon {
    display: block;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    stroke: ${colors.GREY};
  }

  @media (min-width: 769px) {
    display: block;
  }
  @media (max-width: 1304px) {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 95.24%);
  }
`;
export const ArrowNext = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -5px;
  width: 50px;
  height: 240px;
  cursor: pointer;
  display: none;
  &:hover {
    .icon {
      stroke: ${colors.RED};
    }
  }
  .icon {
    display: block;
    width: 100%;
    height: 100%;
    stroke: ${colors.GREY};
  }

  @media (min-width: 769px) {
    display: block;
  }
  @media (max-width: 1304px) {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 95.24%);
  }
`;

export const ArrowNextCretor = styled(ArrowNext)`
  height: 360px;
`;

export const ArrowPrevCretor = styled(ArrowPrev)`
  height: 360px;
`;

export const Item = styled.p`
  display: inline-block;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
  position: relative;
  bottom: -2px;
  margin: 0;
  color: ${colors.GREY_SEMIDARK};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  &:before {
    content: "";
    display: inline-block;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 1px;
    background-color: ${colors.GREY_SEMIDARK};
  }
`;

export const CreatorImage = styled.div`
  flex-shrink: 0;

  img {
    object-fit: cover;
    object-position: center;
  }
`;

export const UserInfo = styled.div`
  font-size: 14px;
  font-family: ${fonts.latoRegular};
  color: ${colors.GREY_SEMIDARK};
  &:not(:last-of-type) {
    &:after {
      content: ",";
      margin-right: 6px;
      font-size: 14px;
      display: inline-block;
      bottom: -1px;
    }
  }

  &:last-of-type:after {
    content: "";
  }
`;

export const UserBio = styled.p`
  margin-top: 6px;
  color: ${colors.WHITE};
  font-size: 14px;
  margin-bottom: 0;
`;

export const CardTitle = styled.div`
  cursor: pointer;
  margin-bottom: 15px;
  font-family: ${fonts.latoBold};
  font-size: 16px;
  line-height: 138.3%;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.creator {
    margin-bottom: 5px;
  }

  &.spotlight {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 15px;
  }
`;

export const CreatorCardBody = styled.div`
  position: absolute !important;
  left: 12px;
  bottom: 22px;
  z-index: 12;
`;

export const CreatorTitle = styled.div`
  color: ${colors.WHITE};
  font-size: 18px;
  font-family: ${fonts.latoBold};
`;

export const Creator = styled.div`
  display: flex;
  align-items: center;

  img,
  .buyer {
    display: block;
    height: 40px !important;
    width: 40px !important;
    border-radius: 100%;
    margin: 0;

    &:after {
      display: none;
    }
  }

  span {
    margin: 0 5px 0 10px;
    font-family: ${fonts.latoRegular};
    color: ${colors.HOME_HEADERS};
  }
`;

export const CardActionIcons = styled.div`
  display: flex;
  align-items: center;
  .icon-plus {
    fill: ${colors.WHITE};
  }

  ${CardIconWrap} + ${CardIconWrap} {
    margin-left: 15px;
  }
`;

export const CardAction = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;

  ${CardTime} {
    color: ${colors.WHITE};
  }
`;

export const CreatorCard = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    border-radius: 20px;
    mix-blend-mode: multiply;
    width: 240px;
    height: 360px;
    background-image: linear-gradient(180deg, rgba(21, 22, 22, 0) 66.15%, rgba(34, 36, 36, 0.9) 87%) !important;
  }

  ${CardImage} {
    ${NoImage} {
      position: absolute !important;
    }
    min-width: 240px;

    width: 100%;
    height: 100%;
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

export const LinkToProfile = styled.span`
  cursor: pointer;
  display: inline-flex;
  color: ${colors.RED};
  font-family: ${fonts.latoRegular};
  margin-top: 15px;

  .icon {
    stroke: ${colors.RED};
    margin-left: 10px;
    height: 20px;
  }
`;

export const SpotlightCard = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15px;
    margin: 0;
    background: #f2f3f5;
    border-radius: 20px;
  }

  ${CardImage} {
    min-width: 412px;
    width: 412px;
    max-height: 412px;
    height: 412px;
    margin-bottom: 0;
    margin-right: 20px;
    padding-top: 0;
    position: absolute;
    left: 0;
    border-radius: 20px;

    @media (max-width: 1199px) {
      min-width: 325px;
      width: 325px;
      max-height: 325px;
      height: 325px;
    }

    @media (max-width: 991px) {
      max-width: 328px;
      width: 100%;
      position: relative;
      margin: -15px auto 0;
      min-width: inherit;
      img {
        max-width: 328px;
      }
    }
  }

  ${CardBody} {
    height: 100%;
    width: calc(100% - 100px);
    background: ${colors.GREY_LIGHT};
    border-radius: 20px;
    padding: 40px 120px 40px 362px;
    min-height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1199px) {
      width: calc(100% - 70px);
      padding: 30px 50px 30px 300px;
    }

    @media (max-width: 991px) {
      width: 100%;
      padding: 30px 16px;
      min-height: inherit;
    }
  }

  ${UserInfo} {
    @media (max-width: 991px) {
      text-align: center;
    }
  }
`;

export const CardDescription = styled.p`
  font-size: 16px;
  line-height: 27px;
  font-family: ${fonts.latoRegular};
  margin-bottom: 35px;
  margin-top: 0;
`;

export const AuthorName = styled.p`
  margin: 0 0 3px;
  font-size: 16px;
  color: ${colors.RED};
  font-family: ${fonts.latoBold};

  @media (max-width: 991px) {
    text-align: center;
  }
`;

export const ButtonToProfile = styled.a`
  display: inline-flex;
  width: fit-content;
  height: 37px;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid ${colors.RED};
  color: ${colors.RED};
  font-family: ${fonts.latoRegular};

  @media (max-width: 991px) {
    align-self: center;
  }

  .icon {
    stroke: ${colors.RED};
    margin-left: 10px;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;

  &.slider-type1 {
    .slick-slider {
      @media (min-width: 1025px) {
        padding: 0 50px;
        margin-left: -50px;
        margin-right: -50px;
      }
    }
    .slick-list {
      margin-right: -15px;
      @media (min-width: 480px) {
        margin-right: -20px;
      }
    }
    .slick-list {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        display: block;
        width: 84px;
        z-index: 1;
        @media (max-width: 1300px) {
          background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 90%);
        }
      }
      &:hover {
        &:after {
          display: none;
        }
      }
    }
    .slick-track {
      left: -10px;

      @media (min-width: 1304px) {
        left: -15px;
      }
    }
  }

  &.banner-slider {
    .slick-list:after {
      display: none;
    }
    .slick-slider {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }
    .slick-dots {
      margin-top: -10px;
    }
  }
  &.middle {
    ${Card} {
      padding-top: 16px;
      padding-left: 14px;
      padding-right: 14px;
      width: 270px !important;
      @media (min-width: 769px) {
        width: 270px !important;
      }
      ${CardImage} {
        box-shadow: 8px -8px #dcdee0, 16px -16px #f2f3f5;
      }
    }
  }
  &.big {
    ${Card} {
      ${CardImage} {
        padding-top: 360px;
      }
    }
  }
  &.large {
    margin: 0 -16px;
    @media (min-width: 768px) {
      margin: 0;
    }
    .slick-slider {
      @media (min-width: 768px) {
        padding: 0 50px;
      }
      @media (min-width: 1304px) {
        padding: 0 57px;
        margin-left: -57px;
        margin-right: -57px;
      }
    }
    ${Card} {
      padding: 0;
      width: 100% !important;
    }

    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
      background: none;
    }

    @media (max-width: 991px) {
      .slick-list {
        padding-top: 15px;
        margin: 0;
      }
    }
  }
  &.сelebrity_mobile {
    ${CardImage} {
      width: 312px;
      height: 312px;
      max-width: 312px;
      max-height: 312px;
      box-shadow: 8px -8px #dcdee0, 16px -16px #f2f3f5;
    }
    ${Card} {
      padding-left: 26px;
      padding-right: 26px;
      padding-top: 16px;
      width: 400px !important;
    }

    ${CardTitle} {
      max-width: 312px;
    }

    .slick-slider {
      margin-left: -16px;
    }
  }
  &.сelebrity {
    @media (min-width: 768px) {
      margin: 0;
    }

    .slick-slider {
      margin-left: -26px;
    }

    ${PaymentBackground} {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      min-width: 83px;
      height: 39px;
    }

    ${Card} {
      padding-left: 26px;
      padding-right: 26px;
      padding-top: 16px;
      width: 100% !important;
    }

    ${CardTitle} {
      font-size: 22px;
      max-width: 400px;
    }

    ${Creator} {
      img,
      .buyer {
        height: 46px !important;
        width: 46px !important;
      }
    }

    ${Item} {
      font-size: 18px;
    }

    ${CardIconWrap} {
      width: 26px;
      height: 26px;

      .icon {
        width: 26px;
        height: 26px;
      }
    }

    ${CardTime} {
      font-size: 18px;
    }

    ${CardImage} {
      width: 410px;
      height: 400px;
      max-width: 410px;
      max-height: 400px;
      box-shadow: 8px -8px #dcdee0, 16px -16px #f2f3f5;
    }

    .slick-arrow {
      top: 50%;
      transform: translateY(-50%);
      background: none;
    }
  }

  ${Card} {
    width: 260px !important;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: 1025px) {
      width: 270px !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }
  .slick-track:after {
    display: table;
    clear: both;
    content: "";
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    div > div {
      outline: none;
    }
  }
  [dir="rtl"] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-disabled {
    opacity: 0;
    cursor: default;
  }

  .slick-dots {
    padding: 0;
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 7px;
    height: 7px;
    padding: 0;
    margin: 0 4px;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 7px;
    height: 7px;
    padding: 0;
    cursor: pointer;
    border: 0;
    outline: 0;
    position: relative;
    background: rgba(32, 36, 41, 0.2);
    border-radius: 100%;
  }

  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }

  .slick-dots li.slick-active button {
    opacity: 0.75;
    background: ${colors.RED};
  }
  ${CardInfo} {
    > span + span {
      margin-left: 4px;
    }
  }

  /* fix for jumping slider from github issue https://github.com/kenwheeler/slick/issues/2711#issuecomment-462190906 */
  .slick-slider {
    div {
      transition: none;
    }
  }
`;

export const SliderWrapperSteps = styled(SliderWrapper)`
  .slick-slider {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    overflow: hidden;
  }
  .slick-slide + .slick-slide {
    padding-left: 9px;
    @media (min-width: 769px) {
      padding-left: 0;
    }
  }
`;

export const CollectionsSlider = styled.div`
  ${CardImage} {
    max-width: 330px !important;
  }
`;

export const MacOsStubContainer = styled.div`
  height: 5vh;
  width: 20vw;
`;
